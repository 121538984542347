'use client'
import React, { useState } from "react"
// import Image from 'next/image'
import styles from './styles/page.module.scss'

import LoadingDialog from "./components/LoadingDialog"
import Canvas from '@/app/components/BlankCanvas';

export default function Home() {

  const [loading, setLoading] = useState(true)

  const handleClose = () => {

  }

  return (
    <Canvas>
      <main className={styles.main}>
        <div>
            <section>
              <LoadingDialog
                open={loading}
                duration={10}
                onClose={handleClose}
                />
            </section>
              
          </div>
      </main>
    </Canvas>
  )
}
